.article-top-level-container {
  margin: 0;
  padding: 0;
  margin-top: 70px;
  height: 100%;
}

.article-row {
  width: 100vw;
  padding: 20px;
}

.article-col {
  padding: 0px 100px;
}

.discussion-col {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.follow-button {
  background: transparent;
  color: rgba(33, 122, 255, 0.85);
  border: 1px solid rgba(33, 122, 255, 0.85);
  font-size: 12px;
  height: 20px;
  padding: 0 8px;
  margin-left: 10px;
}

.follow-button:hover {
  background: rgba(33, 122, 255, 0.85);
  border: 1px solid rgba(33, 122, 255, 0.85);
}

.preview-name-row p {
  height: 100%;
}

.preview-info-row {
  margin: 0;
  padding: 0;
}

.preview-info-row .col {
  padding: 0;
}

.preview-info-row .row {
  padding: 0;
  margin: 0;
  font-size: 12px;
  margin-bottom: 10px;
}

.info-piece {
  margin-left: 10px;
  background: rgba(33, 122, 255, 0.85);
  border-radius: 25px;
  padding: 0 8px;
  min-width: 40px;
  font-size: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

.info-piece:hover {
  cursor: default;
}

.likes-dislikes svg {
  color: rgba(0, 0, 0, 0.3);
  width: 20px;
  height: 20px;
  transition: color 0.13s;
}

.likes-dislikes svg:hover {
  color: rgba(33, 122, 255, 0.85);
  border-radius: 2px;
  cursor: pointer;
}

.article-collections-add-icon {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.6);
}

.article-collections-add-icon:hover {
  color: rgba(33, 122, 255, 0.85);
  cursor: pointer;
}

.likes-dislikes .thumb-up {
  margin-right: 5px;
}

.likes-dislikes .thumb-down {
  margin-left: 15px;
  margin-right: 5px;
}

.likes-dislikes .bookmark {
  margin-left: 15px;
}

.discussion-col {
  margin: 0;
  /* padding: 0;
  padding: 5px; */
  justify-content: flex-start;
}

.discussion-col h3 {
  color: rgba(33, 122, 255, 0.85);
  margin-bottom: 20px;
}

.discussion-comments-row {
  width: 100%;
  padding: 0;
  margin: 0;
}

.discussion-comments-col {
  margin: 0;
  padding: 0;
}

.article-comment-row {
  padding: 0;
  margin: 0;
}

.article-comment-first-col,
.article-comment-second-row {
  padding: 0;
  margin: 0;
}

.article-comment-image-col {
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  /* margin-right: 5px; */
}

.article-comment-image-col .preview-user-image {
  margin: 0;
  padding: 0;
  max-height: 100%;
  max-width: 100%;
  padding: 5px;
  object-fit: cover;
}

.article-comment-content-col {
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 10px 10px 0px;
}

.article-comment-content-col .comment-info-row {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.comment-info-row .username {
  font-weight: bold;
  color: rgba(33, 122, 255, 0.85);
  margin: 0;
  padding: 0;
  margin-right: 20px;
}

.comment-info-date-published {
  color: grey;
  font-size: 10px;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.comment-content-row {
  padding: 0;
  margin: 0;
  font-size: 12px;
  padding-right: 20px;
}

.comment-likes-dislikes-row {
  margin: 0;
  padding: 0;
  font-size: 12px;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.comment-likes-dislikes-row svg {
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.13s;
}

.comment-likes-dislikes-row svg:hover {
  color: rgba(33, 122, 255, 0.85);
  cursor: pointer;
}

.comment-likes-dislikes-row .thumb-up {
  margin-right: 5px;
}

.comment-likes-dislikes-row .thumb-down {
  margin-left: 10px;
  margin-right: 5px;
}

.preview-body-row {
  margin-top: 20px;
}

.preview-body-row p {
  padding: 0 !important;
  margin: 0;
}

.loading-spinner-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  position: relative;
  background-color: #073966;
  background-image: linear-gradient(to top, #031729, #073966);
}

.loading-spinner {
  position: absolute;
  top: 50%;
  bottom: 50%;
}

.related-articles-top-level-row {
  width: 100vw;
  justify-content: center;
}

.related-articles-row {
  /* padding: 0 40px; */
  padding: 0;
  margin-bottom: 40px;
  justify-content: center;
  /* background: red; */
  width: 100vw !important;
  max-width: 100vw !important;
}

.related-article-col {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  padding-bottom: 10px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  border-radius: 2px;
}

.single-report-col {
  margin: 0;
  padding: 0;
  margin: 10px;
  margin-top: 20px;
  padding-bottom: 10px;
  background: white;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.related-article-col:not(:last-child) {
  margin-right: 20px;
}

.related-article-col .row {
  margin: 0;
  padding: 0;
}

.related-article-image-row {
  margin: 0;
  padding: 0;
}

.related-article-navlink-row {
  width: 100%;
}

.related-article-image {
  height: 200px;
  width: 100%;
  border-radius: 2px;
  object-fit: cover;
}

.related-article-title-row {
  font-weight: bold;
  padding: 0;
  margin: 0;
  padding-left: 10px !important;
  margin-top: 10px !important;
}

.related-article-title-row a {
  color: white;
}

.related-article-title-div:hover {
  color: white;
}

.related-article-info-row {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  padding: 0;
  margin: 0;
  padding-left: 10px !important;
}

.related-article-info-row a {
  color: rgba(255, 255, 255, 0.6);
  margin-right: 5px;
}

.related-article-info-row a:hover {
  color: white;
}

.related-article-info-div {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 3px;
}

.related-articles-header-row {
  margin-top: 10px;
  justify-content: center;
}

.related-articles-header-text {
  color: white;
  font-size: 20px;
}

.related-articles-render-articles-row {
  justify-content: center;
}

.comment-form {
  width: 100%;
}

.comment-form .row {
  margin: 0;
  justify-content: flex-end;
}

.comment-box {
  border-radius: 0;
  background: transparent;
  color: white;
  border: 1px solid #1588ee;
  min-height: 40% !important;
  max-height: 60% !important;
  height: 100px !important;
}

.comment-box:focus {
  background: transparent;
  color: white;
}

.post-button {
  margin: 10px 0;
  padding: 0;
  padding: 0 10px;
  border: 1px solid #1588ee;
  background: transparent;
  color: #1588ee;
  border-radius: 0;
  width: 100px;
}

.post-button:hover {
  background: #1588ee;
  color: white;
  border: 1px solid #1588ee;
}

#related-article {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.preview-report-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.preview-report-image-col {
  justify-content: center;
  vertical-align: center;
  align-items: center;
  display: flex;
}

.preview-contributor-info-col {
}

.article-container {
  color: #858796 !important;
}

.article-container .article-top-row {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
}

.article-container .article-top-row .col-md-2,
.article-container .article-top-row .col-md-8,
.article-container .article-top-row .col-md-1 {
  padding: 0;
  margin: 0;
}

.article-container .article-top-row .article-first-col {
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  padding: 20px;
}

.render-analytics-row {
  margin: 0;
  padding: 0;
}

.article-container .article-top-row .article-third-col {
}

.article-container .article-top-row .article-second-col {
}

.render-article-top-row {
  margin: 0;
  padding: 0;
  padding-top: 10px;
  /* margin-bottom: 20px; */
}

.render-article-image-col {
  margin: 0;
  padding: 0;
  border-radius: 5px;
}

.render-analytics-likes-dislikes {
  width: 100%;
  margin-top: 50%;
  margin-bottom: 10px;
  position: sticky !important;
}

.render-analytics-likes-dislikes .col {
  margin: 0;
  padding: 0;
  width: 100%;
}

.render-analytics-likes-dislikes .col .row {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
}

.render-analytics-likes-dislikes .thumb-up,
.render-analytics-likes-dislikes .thumb-down,
.render-analytics-likes-dislikes .bookmark {
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.13s;
}

.render-analytics-likes-dislikes .thumb-up:hover,
.render-analytics-likes-dislikes .thumb-down:hover,
.render-analytics-likes-dislikes .bookmark:hover {
  cursor: pointer;
}

.render-analytics-topics-row {
  /* background: orange; */
  /* border-top: 1px solid rgba(0, 0, 0, 0.13); */
  padding-top: 10px;
  width: 100%;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.54);
}

.render-analytics-topics-row .col {
  margin: 0;
  padding: 0;
}

.render-analytics-topics-row .col .row {
  margin: 0;
  padding: 0;
}

.render-analytics-topics-row .topics-row {
  margin: 0;
  padding: 0;
  width: 100%;
}

.render-analytics-topics-row .topics-row .col {
  margin: 0;
  padding: 0;
}

.render-analytics-topics-row .individual-topic {
  background: rgba(33, 122, 255, 1);
  color: white;
  margin: 0;
  padding: 0;
  padding: 0 15px;
  border-radius: 2px;
  margin-right: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.render-analytics-category-row {
  /* border-top: 1px solid rgba(0, 0, 0, 0.13); */
  padding-top: 10px;
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
}

.render-article-image {
  margin: 0;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.render-article-info-col {
  margin-bottom: 20px;
}

.render-article-title-row h1 {
  color: white;
}

.render-article-summary-row h2 {
  color: rgba(255, 255, 255, 0.6);
}

.render-article-user-image-col {
  margin: 0;
  padding: 0;
}

.render-article-user-image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.render-article-user-info-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.render-article-name-row {
}

.render-article-name-row p {
  font-size: 14px;
  margin: 0;
  /* color: rgba(0, 0, 0, 0.6); */
}

.article-user-name {
  color: rgba(255, 255, 255, 0.6);
}

.article-user-name:hover {
  color: white;
}

.render-article-date-row {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.render-article-date-row .col {
  justify-content: flex-start;
  display: flex;
}

.render-article-body-row {
  margin: 0;
  padding: 0;
}

.render-article-body-row * {
  color: white !important;
  background: transparent !important;
  background-color: transparent !important;
  color: white !important;
  font-size: 16px;
}

.render-article-body-row * a {
  color: #ff6b00 !important;
  background: transparent !important;
  background-color: transparent !important;
}

.render-article-body-row * a * {
  color: #ff6b00 !important;
  background: transparent !important;
  background-color: transparent !important;
}

.render-article-date-row .left-justified-col {
  align-items: flex-start !important;
}

.article-contributor-info-row {
  margin: 0;
  padding: 0;
  width: 100%;
  /* background: red; */
}

.article-contributor-info-row .col-md-8 {
  margin: 0;
  padding: 0;
  /* background: orange; */
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  margin-bottom: 10px;
}

.article-topics-row,
.article-topics-row .col-md-2,
.article-topics-row .col-md-8,
.article-topics .row {
  margin: 0;
  padding: 0;
}

.article-topics a:hover {
  text-decoration: none;
}

.article-topics-row {
  margin-bottom: 20px;
}

.article-topics-row .individual-topic {
  margin: 0;
  padding: 0;
  padding: 2px 10px;
  background: transparent;
  color: rgba(33, 122, 255, 1);
  border: 1px solid rgba(33, 122, 255, 1);
  border-radius: 2px;
  margin-right: 10px;
  transition: background 0.12s color 0.12s;
}

.article-topics-row .individual-topic:hover {
  cursor: pointer;
  background: rgba(33, 122, 255, 1);
  color: white;
}

.article-discussion-row {
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
  padding: 20px 0;
  /* height: 100%; */
}

.render-discussion-col {
  margin: 0;
  padding: 0;
}

.render-discussion-col h3 {
  color: black;
  font-size: 18px;
}

.render-article-comment-row,
.render-article-comment-first-col,
.render-article-comment-second-row,
.render-article-comment-image-col,
.render-article-comment-content-col,
.render-comment-info-row,
.render-comment-info-date-published,
.render-comment-content-row,
.render-comment-likes-dislikes-row {
  margin: 0;
  padding: 0;
}

.render-article-comment-row {
  padding: 10px;
  border-radius: 0;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
  color: white;
}

.render-article-comment-second-row {
  margin-bottom: 15px;
}

.render-article-comment-image-col {
}

.render-user-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 0;
}

.render-article-comment-content-col .render-comment-info-row .username {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.render-article-comment-content-col .render-comment-info-row .username:hover {
  font-size: 14px;
  color: white;
  text-decoration: underline;
}

.render-comment-info-date-published {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.render-comment-content-row {
  margin-bottom: 10px;
}

.render-comment-likes-dislikes-row {
  font-size: 14px;
  align-items: center;
}

.render-comment-likes-dislikes-row .thumb-up,
.render-comment-likes-dislikes-row .thumb-down {
  transition: color 0.13s;
  margin-right: 5px;
}

.render-comment-likes-dislikes-row .thumb-up:hover,
.render-comment-likes-dislikes-row .thumb-down:hover {
  color: rgba(33, 122, 255, 1);
  cursor: pointer;
}

.render-comment-likes-dislikes-row .thumb-down {
  margin-left: 15px;
}

.render-discussion-show-more-row {
  margin: 0;
  padding: 0;
  justify-content: center;
  width: 100%;
}

.pagination .active {
  margin: 0;
  padding: 0;
  background: transparent !important;
}

.pagination .active .page-link {
  margin: 0;
  padding: 0;
  background: rgba(33, 122, 255, 1);
  border-radius: 5px;
}

.pagination .page-link {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  justify-content: center;
  background: transparent;
  border: none;
  font-size: 12px;
  vertical-align: center;
  align-items: center;
  display: flex;
  font-weight: 100;
}

.render-discussion-show-more-row div {
  text-decoration: underline;
  color: rgba(33, 122, 255, 1);
  margin-right: 10px;
}

.render-discussion-show-more-row div:hover {
  cursor: pointer;
  color: rgba(33, 122, 150, 1);
}

.render-analytics-likes-dislikes .col .row,
.render-analytics-likes-dislikes .col {
  margin: 0;
  padding: 0;
}

.render-analytics-likes-dislikes .icon-col {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.render-analytics-likes-dislikes .analytics-row {
  margin-bottom: 10px !important;
}

.render-analytics-likes-dislikes .values-col {
  justify-content: flex-start;
  display: flex;
  color: white;
  font-size: 14px;
  align-items: center;
}

.render-analytics-likes-dislikes .thumb-up,
.render-analytics-likes-dislikes .thumb-down,
.render-analytics-likes-dislikes .bookmark,
.render-analytics-likes-dislikes .fa-reg-eye {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.6);
}

.render-analytics-likes-dislikes #isBookmarked,
.render-analytics-likes-dislikes #blue-icon {
  color: rgba(33, 122, 255, 1);
  transition: color 0.13s;
}

.render-analytics-likes-dislikes #isBookmarked:hover,
.render-analytics-likes-dislikes #blue-icon:hover {
  color: white;
}

.article-related-articles-row,
.article-related-articles-col,
.related-artcles-row {
  margin: 0;
  padding: 0;
}

.article-related-articles-row {
  background: rgba(0, 0, 0, 0.05);
}

#exclamation-triangle {
  color: rgba(255, 255, 255, 0.6);
}

#exclamation-triangle:hover {
  cursor: pointer;
  color: orange;
}

.report-modal {
  background: rgba(0, 0, 0, 0.3);
  padding-top: 10%;
}

.report-modal .modal-content {
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
}

.report-modal .modal-header {
  border: none;
}

.report-modal .modal-header button {
  color: white;
  font-weight: normal;
}

.report-modal-button-row {
  justify-content: center;
}

.report-modal-button-row button {
  background: transparent;
  border-radius: 0;
  color: #1588ee;
  border: 1px solid #1588ee;
  width: 100px;
}

.report-modal-button-row button:hover {
  background: transparent;
  border-radius: 0;
  color: white;
  border: 1px solid #1588ee;
  background: #1588ee;
}

.report-modal .form-control {
  color: white;
  background: #083f70;
}

.report-modal-form-control {
  border: 1px solid #1588ee;
  border-radius: 0;
  color: white;
}

.report-modal-form-control-textarea {
  border-radius: 0;
  border: 1px solid #1588ee;
  color: white;
}

.report-not-found-container {
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  margin-top: 90px;
  background-color: rgb(211, 211, 224);
  background-image: url("../../../images/TokenProfile/search-tokens-background.png");
}

.report-not-found-col {
  margin-top: 8%;
}

.report-not-found-row {
  justify-content: center;
  font-size: 32px;
  /* color: white; */
}

.report-not-found-bottom-row {
  margin-top: 20px;
}

.share-buttons-row {
  margin-bottom: 20px;
}

.share-buttons-row .share-button:not(:last-child) {
  margin-right: 2px;
}

.article-collections-add-popover {
  max-width: 250px;
  width: 250px;
  background: #1588ee;
  background-image: linear-gradient(to top, #083f70, #136dbd);
  border: 1px solid #1588ee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0;
}

.article-collections-add-popover .popover-header {
  background: transparent;
  border-bottom: none;
}

.article-collections-search-bar {
  height: 28px;
  font-size: 12px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #5db2fe;
  color: white;
}

.article-collections-search-bar:focus {
  background: rgba(0, 0, 0, 0.3);
  color: white;
}

.article-collections-search-bar::placeholder {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.article-collections-search-row {
  align-items: center;
}

.article-collections-search-icon-col {
  justify-content: center;
  display: flex;
}

.article-single-collection {
  font-size: 10px;
  color: white;
}

.article-single-collection:not(:last-child) {
  border-bottom: 1px solid #1588ee;
}

.article-single-collection:hover {
  background: rgba(0, 0, 0, 0.04);
}

.article-collections-row {
  align-items: center;
}

.article-collections-button-row {
  align-items: center;
}

.article-collections-icon {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.article-collections-add-icon:hover {
  color: white;
}

.article-collections-add-button {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.article-collections-add-button:hover {
  color: white;
  cursor: pointer;
}

.article-collections-tooltip {
  font-size: 10px;
}

.article-no-collections-found {
  justify-content: center;
  display: flex;
}

.view-article-container {
  /* height: calc(100% - 70px); */
  height: 100vh;
  /* background: var(); */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.new-article-container {
  width: 1100px;
  margin: 16px 0;
  background: white;
  padding: 32px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: max-content;
}

.article-category {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
  color: white;
  font-size: 14px;
  margin-right: 4px;
  height: max-content;
  min-width: 20px;
  justify-content: center;
  display: flex;
  padding: 2px 8px;
  border-radius: 3px;
}

.discover-article-categories-container {
  display: flex;
  margin-left: 32px;
}
