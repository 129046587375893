.show-article-container {
  padding: 16px !important;
}

.show-article-title-row {
  margin-bottom: 8px;
}

.show-article-title-row h1 {
  margin: 0;
  font-size: 42px;
}

.show-article-summary-row {
  margin-bottom: 8px;
}

.show-article-summary-row h2 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 20px;
}

.show-article-user-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 8px;
}

.show-article-name-row {
  font-size: 14px;
}

.show-article-date-row {
  font-size: 12px;
}

.show-article-user-row {
  /* margin-bottom: 64px; */
  margin-bottom: 16px;
}

.preview-article-modal-header {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.new-article-container {
  color: var(--darkText);
  background: var(--darkPurple);
}

.show-article-image-row {
  justify-content: center;
  margin-bottom: 16px;
}

.show-article-image {
  max-height: 400px;
}

.create-report-loading-row {
  height: calc(100% - 70px);
  justify-content: center;
  align-items: center;
}

.create-report-spinner {
  color: #858796 !important;
  width: 100px;
  height: 100px;
}
