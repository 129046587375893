.user-lookup-container {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}

.user-lookup-section {
  background: white;
  width: 100%;
}

.user-lookup-form {
  height: 50px;
}

.user-lookup-form-row {
  margin: 0;
  align-items: center;
  padding-bottom: 8px;
}

.user-lookup-pagination-row a {
  color: #858796 !important;
}

.user-lookup-formcontrol {
  margin-left: 8px;
  width: 400px;
}

.user-lookup-pagination-row {
  justify-content: center;
  margin-top: 16px;
}

.user-lookup-label {
  margin: 0;
}

.user-list-section {
  width: 100%;
  display: block !important;
  padding: 0 !important;
  margin: 0 !important;
}

.user-list-top-row {
  margin: 0;
  padding-left: 40px;
  padding-top: 16px;
  width: 100%;
  background: white;
  z-index: 2;
  position: fixed;
  top: 0;
  border-bottom: 1px solid black;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2),
    0 6px 20px -20px rgba(0, 0, 0, 0.19);
}

.user-list-items-row {
  margin: 0;
  /* padding: 0; */
  margin-top: 20px;
  padding-top: 120px;
  position: relative;
}

.user-list-items-col {
  width: 100%;
}

.user-list-users-container {
  padding: 8px !important;
  padding-top: 128px !important;
}

.user-list-user-row {
  margin: 0;
  height: 40px;
  align-items: center;
}

.user-list-user-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.user-list-user-row .col {
}

.user-list-username {
  font-weight: bold;
  font-size: 14px;
}

.user-list-status {
  font-size: 14px;
}

.user-list-username:hover {
  color: #0182e7;
  cursor: pointer;
  text-decoration: underline;
}

.user-list-selected-user-col {
  margin-left: 15px;
  margin-top: 40px;
}

.back-to-user-list-button {
  font-weight: bold;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.back-to-user-list-button:hover {
  color: #0182e7;
  cursor: pointer;
  text-decoration: underline;
}

.user-list-selected-user-info-row {
  height: 40px;
}

.user-list-selected-user-info-row:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}

.user-list-selected-user-info-row p {
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
}

.user-list-selected-user-info-row label {
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
}

.user-list-button-row {
  height: 100%;
  align-items: center;
  padding-left: 15px;
}

.user-list-toggle-button {
  height: 30px;
  margin-left: 10px;
  padding: 0;
  width: 60px;
}

.table-loading-row {
  justify-content: center;
}
