.feed-container {
  /* height: 100%; */
  padding: 16px;
  min-height: 100vh;
  /* background: #f8f9fc; */
}

.discover-banner {
  border-radius: 0;
  background: var(--darkPurple);
  color: var(--darkText);
  font-size: 18px;
  padding: 16px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.discover-banner-header {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}

.discover-banner-filter-row {
  /* justify-content: center; */
  display: flex;
  align-items: center;
}

.discover-banner-dropdown:not(:last-child) {
  margin-right: 20px;
}

.discover-banner-dropdown-toggle {
  background: white !important;
  color: #858796 !important;
  border: 1px solid #858796 !important;
  border-radius: 2px;
  width: 160px;
}

.discover-banner-dropdown-toggle:hover {
  cursor: pointer;
  background: transparent;
  color: #858796;
  border: 1px solid #858796;
}

.discover-banner-dropdown-menu {
  padding: 0;
  margin: 0;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.discover-banner-form-input-radio {
  color: #858796;
}
.discover-banner-form-input-radio input:hover {
  cursor: pointer;
}

.discover-spinner-container {
  padding: 0;
}

.discover-spinner-container .row {
  justify-content: center;
  padding: 32px;
}

.discover-spinner {
  color: #858796;
  width: 80px;
  height: 80px;
}

.discover-content-row {
  margin-top: 20px;
  padding: 5rem;
  padding-top: 0;
  justify-content: center;
  min-height: max-content;
}

.discover-content-column {
  color: white;
  margin: 0;
  padding: 0;
}

.discover-content-card-row {
  justify-content: center;
}

.discover-content-cardcolumns {
  column-count: 4;
  height: max-content;
}

.discover-card {
  border-radius: 2px;
  background: white;
  max-height: 420px;
}

.discover-report-card-image-link {
  color: #858796 !important;
  width: 100% !important;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.discover-report-card-image-link:hover {
}

.discover-report-card-image {
  border-radius: 0;
  max-height: 200px;
  width: 100%;
  object-fit: contain;
}

.discover-card-title-row {
  margin: 10px 10px 0 10px;
}

.discover-card-title-row a {
}

.discover-report-card-title {
  font-size: 18px;
  color: #858796 !important;
}

.discover-report-card-summary {
  margin: 0 10px 10px 10px;
  color: rgba(255, 255, 255, 0.7);
}

.discover-report-card-info-row {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.discover-report-card-info-row:hover,
.discover-contributor-card-info-row:hover {
  cursor: default;
}

.discover-report-card-info-row .col {
  margin: 0;
  padding: 0;
}

.discover-report-card-icon-row {
  justify-content: center;
  align-items: center;
  color: #858796;
  font-size: 12px;
}

.discover-report-card-icon {
  margin-right: 10px;
  font-size: 14px;
}

.discover-card-type-tag-row {
  margin: 0;
  padding: 0;
  margin: 0 0 10px 10px;
}

.discover-card-type-tag {
  color: #858796;
  background: rgba(0, 0, 0, 0.13);
  padding: 2px 10px;
  border-radius: 100px;
  font-size: 10px;
  cursor: default;
}

.discover-card-contributor-col {
  margin: 0;
  padding: 0;
  padding: 10px;
}

.discover-card-contributor-top-row {
  align-items: center;
  margin-bottom: 10px;
}

.discover-card-contributor-image {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 10px;
}

.discover-card-contributor-name {
  font-size: 18px;
  margin-bottom: 10px;
  color: #858796;
}

.discover-card-contributor-name:hover {
  color: #858796;
}

.discover-collection-card-image {
  border-radius: 0;
  max-height: 200px;
  width: 100%;
  object-fit: cover;
}

.discover-collection-card-title {
  font-size: 18px;
  color: #858796 !important;
}

.discover-collection-card-title:hover {
  text-decoration: underline;
  color: #858796 !important;
}

.discover-collection-card-title a {
  color: #858796 !important;
}

.discover-explore-content-icon {
  color: #858796;
  font-size: 30px;
}

.discover-explore-content-icon#active {
  color: black;
}

.discover-explore-content-icon:not(:last-child) {
  margin-right: 10px;
}

.discover-explore-content-icon:hover {
  color: black;
  cursor: pointer;
}

.discover-content-view-column {
  max-width: 500px;
}

.discover-content-view-column-content {
  max-height: 1000px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.discover-content-view-column-content::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 2px;
}

.discover-content-view-column-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.13);
  border-radius: 2px;
  height: 20px;
}

.discover-content-view-column h5 {
  text-align: center;
}

.discover-content-view-column:not(:last-child) {
  margin-right: 20px;
}

.discover-content-view-column .card {
  margin-bottom: 10px;
}

.discover-content-title {
  color: #858796;
}

.discover-search-bar {
  width: 300px;
  margin-right: 16px;
  color: #858796 !important;
}

.discover-search-bar::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.discover-content-col {
  /* justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; */
  margin-top: 16px;
  padding: 0;
}

.discover-individual-research {
  background: var(--darkPurple);
  width: 1000px;
  height: 170px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.discover-article-user-image {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

.discover-article-image {
  /* height: 100px; */
  width: 160px;
  object-fit: cover;
}

.discover-article-image-container {
  align-items: center;
  display: flex;
}

.discover-article-column {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 16px !important;
  color: #858796 !important;
}

.discover-article-column.user-info {
  min-width: 120px;
}

.discover-article-username {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.discover-article-user-image-icon {
  width: 45px;
  height: 45px;
  color: #858796 !important;
}

.discover-article-date {
  width: 100%;
  font-size: 12px;
  color: #a5a8bb !important;
  /* font-style: italic; */
}

.discover-article-title {
  width: 100%;
  font-size: 22px;
  color: #858796 !important;
}

.discover-article-title a {
  color: #858796 !important;
  padding: 0;
}

.discover-article-summary {
  font-size: 14px;
  width: 100%;
  color: #a5a8bb !important;
}

.discover-broad-column {
  display: flex;
}

.nothing-found {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 800px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  color: #858796 !important;
}
