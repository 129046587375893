.light-chart-row {
  justify-content: center;
}

.light-chart-info-row {
  color: #858796;
  padding: 20px;
  margin-bottom: 20px;
  /* width: 100% !important; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  justify-content: space-around;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.light-chart-container {
  /* width: 100% !important; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
