.footer {
  background: black;
  width: 100%;
  padding: 32px;
  /* padding-top: 32px; */
  /* padding-bottom: 8px; */
  background-image: url('../../images/Footer/background-image.png');
  background-size: 100% 100%;
  /* height: 300px; */
}

.footer-row {
  justify-content: center;
  margin-bottom: 32px;
}

.footer-links-container {
  display: flex;
  width: 600px;
  justify-content: space-between;
}

.footer-navlink {
  color: white;
}

.footer-navlink:hover {
  color: #0182E7;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-icon-container {
  width: 400px;
  justify-content: space-between;
  display: flex;
}

.footer-icon {
    font-size: 30px;
    color: white;
}

.footer-icon:hover {
  color: #0182E7;
}

.all-rights-reserved {
  justify-content: center;
  color: white;
}
