/********************************MISC STYLING**********************************/
.sto-insights-page-container {
  /* background-color: #0d064d; */
  /* margin-top: 93px !important; */
  background: #f8f9fc;
  min-height: 100%;
}

.gradient-background {
  background: linear-gradient(
    135deg,
    rgba(215, 215, 215, 1) 7%,
    rgba(249, 249, 249, 1) 17%,
    rgba(255, 255, 255, 1) 100%
  );
}

.tp-cover-background {
  background-image: url("../../images/HomePage/header-background-image.png");
  background-repeat: no-repeat;
  background-color: #0d064d;
  background-size: 100%;
  height: 600px;
}

.pagination li {
  color: #0182e7;
  border: none;
  width: 35px !important;
  height: 35px !important;
  font-weight: bold;
  font-size: 15px;
  height: 45px;
}

.pagination li a {
  /* width: 0; */
  color: #0182e7;
}

.press-releases-section .pagination li.active,
.news-list-section .pagination li.active {
  background-color: #0182e7 !important;
  border-radius: 5px;
  text-decoration: none;
  width: 35px;
  height: 35px;
}

.pagination li.active a {
  color: white !important;
}

.pagination li a {
  cursor: pointer;
}

.loading-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: auto;
  background-color: rgba(0, 0, 0, 0);
}

.token-alert {
  text-align: center;
  font-weight: bold;
  margin-top: 10%;
}

.upcoming-alert {
  text-align: center;
  font-weight: bold;
  margin-left: 30%;
  margin-top: 10%;
  color: white;
}

/*************************END OF MISC STYLING**********************************/

/*************************SEARCH TOKENS STYLING*******************************/
.search-tokens-container {
  background: transparent;
  height: 700px;
  margin-top: 90px;
}

.search-tokens-section {
  padding-top: 40px;
}

.search-tokens-section h1 {
  color: white;
}

.search-tokens-section .form-row {
  height: 31px;
  margin-bottom: 20px;
}

.search-tokens-section .form-control {
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border-color: #008fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
}

.search-tokens-section .form-control::placeholder {
  color: white;
}

.typeahead ul {
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: none;
}

.typeahead ul::-webkit-scrollbar {
  width: 5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.typeahead ul::-webkit-scrollbar-thumb {
  background: #1063ad;
  border-radius: 2px;
  height: 20px;
}

.typeahead .dropdown-item:hover {
  color: white;
  background: #3590fc;
}

.typeahead a {
  color: white;
  font-size: 14px;
}

.search-tokens-section .token-search-bar {
  background: rgba(0, 0, 0, 0.6);
  color: #008fff;
  height: 31px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  border-radius: 2px;
}

.search-tokens-section .token-search-bar::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.search-tokens-section button {
  height: 31px;
  width: 31px;
  padding: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #008fff;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 2px;
}

.search-tokens-section button:hover {
  border: 1px solid #008fff;
  background: #008fff;
  color: white;
}

.search-tokens-section button .search-icon {
  font-size: 18px;
}

.search-tokens-anchor {
  /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
  position: absolute;
  padding-top: 130px;
  margin-top: -130px;
}

.search-tokens-section h1 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 35px;
}

.tokens-row {
  justify-content: center;
}

/* Individual Token */
.token-card {
  width: 300px;
  height: 390px;
  margin: 10px 20px 0px 20px;
  display: block;
  overflow: hidden;
  background-color: #0d064d;
  /* background-image: linear-gradient(to top, rgb(13, 6, 77, 0.6), rgb(42, 25, 205, 0.9)); */
  background: linear-gradient(
    to top,
    rgb(13, 6, 77, 0.8) 10%,
    rgb(42, 25, 205, 1) 100%
  );
  border: 1px solid #008fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.token-header-row {
  padding-top: 10px;
}

.token-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.token-name {
  position: relative;
}

.token-name h4 {
  color: white;
}

.token-name a:hover {
  color: white;
}

.token-location {
  /* align-items: center; */
  color: rgba(255, 255, 255, 0.6);
}

.token-brief {
  font-size: 14px;
  padding: 20px 20px 0px 20px;
  color: rgba(255, 255, 255, 0.8);
}

.token-industry {
  padding-left: 20px;
}

.token-industry-tag {
  background: #260eff;
  background-image: linear-gradient(to top, #0d064d, #260eff);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
  padding: 0px 10px;
  color: white;
}

.pagination-row {
  margin-top: 40px;
  justify-content: center;
  width: 100%;
}

.pagination-column {
  display: flex;
  justify-content: center;
}
/**********************END OF SEARCH TOKENS STYLING****************************/

/*************************TOKEN INSIGHTS STYLING*******************************/
.token-insights-anchor {
  /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps the user to exactly the right position
    when they navigate using the sidenav.
    */
  position: absolute;
  padding-top: 130px;
  margin-top: -130px;
}

.token-insights-container .container {
  /* background-color: #0d064d;
    background: linear-gradient(to bottom, #0d064d 50%, #4536cc 100%); */
  background: rgba(0, 0, 0, 0.2);
  /*backdrop-filter: blur(3px);*/
  max-width: none;
  height: max-content;
  /* width: 90vw; */
  color: white;
  /* border-radius: 5px; */
}

.token-insights-section {
  padding: 40px 0px 40px;
}

.charts-row {
  color: black;
}

.token-insights-col {
  /* padding: 0; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.token-insights-choropleth-col {
  /* margin-left: 10px; */
}

.token-insights-selector-row {
  justify-content: center;
  color: white;
}

.token-insights-selector-row div {
  margin-right: 20px;
  border: 1px solid white;
  width: 200px;
  text-align: center;
  height: 31px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.token-insights-selector-row div:hover {
  cursor: pointer;
}

.pie-chart-section {
  height: 300px;
  z-index: 1;
  width: 300px;
  margin-left: 25px;
  margin-bottom: 10px;
}

.bubble-chart-section {
  height: 300px;
  z-index: 1;
  width: 300px;
  margin-left: 25px;
  border-radius: 5px;
}

.choropleth-section {
  height: 600px;
  z-index: 1;
  padding: 0;
  width: 100%;
}

.choropleth {
  /* width: 100px; */
}

.tooltip-box-choropleth {
  /* right: 700px; */
  /* bottom: 0px; */
  position: absolute;
  border: 1px dotted white;
  width: 20px;
  height: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 5px;
  z-index: 2;
}

.tooltip-box-choropleth:hover {
  cursor: pointer;
  color: orange;
  border-color: orange;
}

.chart-description-tooltip {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  width: 200px;
  border: none;
  border: 1px solid white;
  z-index: 0;
}

.chart-description-tooltip h3 {
  background: rgba(0, 0, 0, 0);
  color: white;
}

.chart-description-tooltip .popover-body {
  color: white;
}

.token-insights-section h1 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 35px;
}

@media only screen and (max-width: 1900px) {
  .token-insights-container .container {
    width: 100vw;
  }
}
/**********************END OF TOKEN INSIGHTS STYLING***************************/

/*************************UPCOMING STYLING*************************************/
.upcoming-events-container {
  background-color: #0d064d;
  background-image: url("../../images/HomePage/timeline-background-copy.png");
  background-size: 100% 650px;
  background-repeat: no-repeat;
  height: 650px;
  /* margin-bottom: 40px; */
}

.upcoming-events-container .container {
  width: 100%;
}

.upcoming-events-section {
  padding: 40px 0px;
  position: absolute;
  left: 0;
  right: 0;
}

.upcoming-events-column {
  min-height: 480px;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
}

.upcoming-events-anchor {
  /*
    Anchors are designed to account for the height of the navbar (90px)
    and the padding/margins at the top of their respective containers (40px).
    This ensures that smooth-scrolling snaps  the user to exactly the right position
    when they navigate using the sidenav.
    */
  position: absolute;
  padding-top: 130px;
  margin-top: -130px;
}

.upcoming-events-section h1 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 35px;
  color: white;
}

.upcoming-sto-subheader {
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.react-calendar {
  border: none;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  margin-top: 40px;
}

.calendar-column {
  padding-left: 8vw;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.react-calendar__tile--active {
  background: #0182e7;
}

.upcoming-events-top-row {
  margin-bottom: -60px;
}

.upcoming-events-row {
  padding-left: 20px;
}

.upcoming-event {
  border-top: 1px solid white;
  /* width: 90%; */
  width: 100%;
  font-size: 14px;
  padding: 5px 0px;
  /* height: 110px; */
}

.event-image-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event-image-figure {
  width: 80px;
  height: 80px;
  margin: 0;
}

.event-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.event-name-column {
  text-align: left;
}

.event-name-column h5 {
  color: white;
}

.event-name-column a:hover {
  text-decoration: underline;
  color: white;
}

.event-date-column {
  text-align: center;
}

.event-brief-column {
  color: white;
}

.event-date-column {
  color: rgba(255, 255, 255, 0.8);
}
/*************************END OF UPCOMING STYLING******************************/

/**************************PRESS RELEASES STYLING******************************/
.press-releases-container {
  background-image: linear-gradient(to bottom, #0d064d 30%, #2310d1 100%);
  padding: 40px 0;
}

.press-releases-container .container-fluid {
  height: 960px;
}

.press-releases-section h1 {
  margin-bottom: 40px;
  text-align: center;
  font-size: 35px;
  color: white;
}

.press-release-header {
  justify-content: center;
  color: white;
  margin-bottom: 20px;
}

.press-release {
  border-top: 1px solid #1586eb;
  height: 120px;
  align-items: center;
}

.press-release-image {
  width: 200px;
  height: 100px;
  object-fit: fill;
}

.press-release-details p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.press-release h5 {
  font-weight: bold;
  font-size: 18px;
  color: white;
}

.press-release a:hover {
  color: white;
}
/**************************END OF PRESS RELEASES STYLING***********************/

/**************************SIDENAV STYLING*************************************/
.sidenav-container-token-profile {
  z-index: 100000;
  display: table;
  right: 0px;
  position: fixed;
  font-weight: normal;
  margin-right: 5px;
  top: 50%;
}

.sidenav-container-token-profile .sidenav-section {
  transition-duration: 0.3s;
  display: table-cell;
  vertical-align: middle;
  margin: 0px;
  color: black;
  font-size: 10pt;
  text-align: right;
}

.sidenav-container-token-profile .sidenav-icon {
  margin: 0px 0px 0px 10px;
  background: #224db7;
  display: inline-block;
  border-radius: 50%;
  height: 12px;
  width: 12px;
}

.sidenav-container-token-profile .sidenav-text {
  font-size: 9pt;
}

.sidenav-container-token-profile .navigation-button {
  margin-bottom: 5px;
}

.sidenav-container-token-profile .navigation-button:hover {
  transition: color 1.5s visibility 1s;
  background: #0b0347;
  cursor: default;
  color: white;
}
/**************************END OF SIDENAV STYLING******************************/

.market-overview-container {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0.4) 0%,
    rgba(9, 9, 121, 0.4) 8%,
    rgba(0, 212, 255, 0.4) 100%
  );
}

.market-overview-header {
  justify-content: center;
  color: white;
}

.market-overview-content {
  justify-content: center;
  color: white;
}

.assets-by-jurisdiction-column {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assets-by-jurisdiction {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 200px;
  border: 1px solid white;
  border-radius: 2px;
}

.abj-header {
  font-weight: bold;
  border-bottom: 1px solid white;
  width: 100%;
  padding: 10px;
}

.abj-body {
  padding: 10px;
}

.search-assets-container {
  /* padding: 20px; */
  /* padding: 0 !important; */
  color: #858796;
  /* justify-content: center; */
}

.search-assets-top-container {
  padding: 16px;
}

.search-assets-background-container {
  background: var(--darkPurple);
  padding: 8px;
}

.search-assets-form-control-new {
  background: transparent !important;
  border: 1px solid var(--darkText);
  color: var(--darkText) !important;
  max-width: 300px;
  /* width: 300px; */
  /* width: 100%; */
}

.saq-label {
  /* width: 100%; */
  width: 240px;
}

.search-assets-query-row svg {
  width: 10%;
  font-size: 60px;
}

.saq-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* width: 40%; */
}

.saq-item .search-assets-form-control-new {
}

.saq-item:not(:last-child) {
  margin-right: 16px;
}

.no-assets-found {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 50%;
}

.asset-type-table-image {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  object-fit: contain;
}

.asset-profiles-table {
  border-radius: 2px;
  color: #858796;
}

.asset-profiles-table th {
  border: none;
}

.asset-profiles-table thead {
}

.asset-profiles-table thead th {
  padding: 10px;
}

.asset-profiles-table tbody th {
  padding: 10px;
}

.th-section {
  text-align: right;
}

.asset-data-row {
}

.asset-data-row {
  align-items: center;
}

.asset-data-row:hover {
  background: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.th-number {
  text-align: center;
  width: 40px;
}

.th-name {
  width: 20%;
}

.th-brief {
  width: 40%;
}

.th-assetType {
  width: 20%;
}

.th-section {
  /* width: 100%; */
}

.filters-for-asset-types-row {
  /* justify-content: space-evenly; */
  /* padding: 10px; */
  padding: 8px 0;
}

.individual-filter:not(:last-child) {
  margin-right: 20px;
}

.bar-chart-section {
  width: 90%;
  min-height: 600px;
}

.bar-chart-container {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    rgba(2, 0, 36, 0.4) 0%,
    rgba(9, 9, 121, 0.4) 8%,
    rgba(0, 212, 255, 0.4) 100%
  );
}

.assets-container {
  padding-top: 40px;
}

.assets-header {
  color: white;
  justify-content: center;
}

.assets-row {
  justify-content: center;
}

.filter-container {
  font-size: 14px;
  margin-right: 16px;
}

.rendered-asset-filter {
  border-radius: 0 !important;
  width: 200px;
  text-align: center;
  color: #858796;
  align-items: center;
}

.rendered-asset-filter:focus {
  color: #858796;
}

.location-filter .rbt-input-main {
  border-radius: 0 !important;
  width: 200px;
  color: #858796 !important;
}

.location-filter .rbt-menu.dropdown-menu {
}

.rendered-asset-filter.location-filter:focus {
  color: #858796;
}

.loading-bar-row {
  width: 100%;
  height: max-content;
  justify-content: center;
}

.loading-spinner {
  color: #858796;
  padding: 60px;
  margin-top: 60px;
}

.asset-loading-row {
  position: absolute;
  left: 50%;
  top: 50%;
}

.asset-data-navlink {
  color: white;
}

.asset-data-navlink:hover {
}

.link-to-search-assets-row {
  margin-top: 20px;
  justify-content: center;
}

.link-to-search-assets {
  color: white;
  font-size: 22px;
}

.link-to-search-assets:hover {
  color: white;
}

.chart-tooltip {
  color: black;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.choropleth-tooltip {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 2px;
}

.responsive-bar-container {
  height: 100%;
  width: 80%;
}

.search-assets-select {
  /* background: #136dbd; */
  /* color: rgba(255, 255, 255, 0.7); */
  color: #858796 !important;
  align-items: center;
  border-radius: 0 !important;
  /* position: relative; */
  max-width: 50%;
}

.search-assets-select:hover {
  cursor: pointer;
}

.asset-card {
}
