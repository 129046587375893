:root {
  /* --sidebarColor: #043d77; */
  --sidebarColor: #595d69;
  --backgroundColor: #f8f9fc;
  --darkPurple: white;
  --darkText: #858b8f;
  --darkHeader: black;
  --sidebarText: rgba(255, 255, 255, 0.8);
  --color-grey: #858b8f;
  --color-black: black;
  --color-border: #858b8f;
  --color-shadow: rgb(4, 61, 119, 0.1);
  --color-up: green;
  --color-down: fireBrick;
  --color-special: #043d77;
  --color-pink: #c42c9f;
  --color-main: #858b8f;
}

html {
  scroll-behavior: smooth;
}

.app-content {
  /* font-family: 'Nunito', sans-serif; */
  font-family: "Open Sans", sans-serif;
  background: #f8f9fc;
  padding: 0 !important;
}

.app-container {
  padding: 0;
  height: 100%;
  min-height: 100%;
}

.app-row {
  height: 100%;
}

.content-pane {
  height: 100vh;
  /* overflow-y: scroll; */
  z-index: 50;
  position: relative;
}

.content-container {
  padding: 0;
}

.content-pane-container {
  height: 100vh;
  padding: 0;
  padding: 16px;
  /* background: #f8f9fc; */
}

.render-loading {
  background: red;
  width: 100% !important;
}

.render-spinner {
  width: 100px;
  height: 100px;
  color: #858796 !important;
}

.test-row {
  justify-content: center;
  height: 100%;
  align-items: center;
}

.extra-small-button {
  font-size: 10px;
  height: 16px;
  padding: 2px 4px;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.teal-button {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
}

.teal-button:hover {
  background: #2c9faf !important;
  border: 1px solid #2a96a5 !important;
  color: black !important;
}

.delete-button {
  background-color: Tomato !important;
  border: 1px solid Tomato !important;
}

.delete-button:hover {
  background-color: FireBrick !important;
  border: 1px solid FireBrick !important;
  color: black !important;
}

#true-content {
  color: MediumSeaGreen !important;
}

#false-content {
  color: red !important;
}

.hidden {
  display: none;
}

.td-row {
  justify-content: space-between;
}

.td-row .individual-form-icon:not(:last-child) {
  margin-right: 4px;
}

.individual-form-icon {
  font-size: 16px;
  color: #858796;
}

.individual-form-icon:hover {
  color: black;
  cursor: pointer;
}

.individual-form-icon-disabled {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 8px;
}

.admin-container {
  padding: 0;
}

.admin-content-pane {
  height: 100vh;
  /* margin-top: -70px; */
  /* padding-top: 4.375rem; */
  overflow-y: scroll;
  z-index: 2;
  position: relative;
}

.admin-content-container {
  padding: 24px !important;
}

.return-to-header {
  align-items: center;
  display: flex;
}

.return-to-header:hover {
  cursor: pointer;
  color: black;
}

.admin-modal {
  color: #858796;
  background: rgba(0, 0, 0, 0.3);
  /* font-family: 'Nunito', sans-serif; */
  font-family: "Open Sans", sans-serif;
}

.admin-modal .modal-content {
  /* padding: 0 10px; */
  border: none;
  border-radius: 2px;
}

.confirm-delete-modal-row {
  align-items: center;
  justify-content: center;
  /* max-width: 1000px; */
}

.confirm-delete-modal-row button:not(:last-child) {
  margin-right: 8px;
}

.confirm-delete-modal-row:not(:last-child) {
  margin-bottom: 16px;
}

.template-edit-form {
  color: #858796;
}

.template-edit-form-group {
  align-items: center;
  justify-content: space-between;
  background: red;
}

.template-edit-div {
  width: max-content !important;
  background: orange;
}

.template-edit-form-label {
  /* position: absolute; */
  margin: 0;
  margin-right: 8px;
}

.template-edit-form-control {
  max-width: 400px;
}

.admin-edit-content-row {
  color: #858796;
  align-items: center;
}

.admin-edit-content-row:not(:last-child) {
  margin-bottom: 8px;
}

.admin-edit-content-col {
  margin-right: 8px;
}

.submission-form-control-label {
  color: #858796;
  margin: 0;
  width: 100%;
}

.admin-edit-content-section {
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  padding: 8px;
}

.admin-edit-individual-field {
  color: #858796;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
  padding: 4px;
}

.configure-button {
  margin-right: 8px;
}

.admin-edit-configure-modal-header {
  width: 100%;
  justify-content: flex-end;
}

.admin-edit-configure-modal-header button {
  margin-left: 8px;
}

.admin-edit-content-subsection {
  margin-bottom: 4px;
  align-items: center;
}

.no-margin {
  margin: 0 !important;
}

.ap-edit-row:not(:last-child) {
  margin-bottom: 8px;
}

.ap-edit-row .col:not(:last-child) {
  margin-right: 8px;
}

.ap-edit-image-container {
  position: relative;
  padding: 8px;
}

.ap-image {
  max-width: calc(100% - 8px);
}

.ap-image-cancel-icon {
  position: absolute;
  font-size: 16px;
  right: 0;
  color: #858796;
}

.ap-image-cancel-icon:hover {
  color: black;
  cursor: pointer;
}

.ap-edit-formfile-container {
  padding-bottom: 19px;
  padding-right: 8px;
  justify-content: center;
  width: 280px;
  height: 100%;
}

.ap-edit-formfile-interior {
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 4px;
}

.ap-edit-formfile {
  width: max-content !important;
}

.common-typeahead input {
  color: #858796 !important;
  height: 31px;
  font-size: 14px;
}

.common-typeahead input:focus {
  color: #858796;
}

.common-typeahead .rbt-input-hint input {
  display: none;
}

.rbt-input-main::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.common-typeahead .rbt-menu .dropdown-item {
  color: #858796 !important;
  font-size: 14px;
}

.ap-edit-content-section {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 8px;
  background: rgba(0, 0, 0, 0.1);
}

.ap-edit-form-info-row {
  margin-bottom: 8px;
}

.ap-edit-publish-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.ap-edit-tabs {
  display: flex;
}

.ap-edit-tab {
  color: #858796;
  padding: 2px 8px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 14px;
}

.ap-edit-tab:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.ap-edit-tab#current-tab {
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid transparent;
}

.ap-offering-details-row {
  margin-bottom: 12px;
}

.ap-formgroup {
  color: #858796;
}

.ap-edit-section-label {
  color: #858796;
  font-weight: bold;
  font-size: 16px;
}

.ap-edit-section-row {
  align-items: center;
  justify-content: space-between;
}

.ap-edit-required-info {
  font-style: italic;
  color: rgba(0, 0, 0, 0.3);
}

.common-date-picker {
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  padding-left: 6px;
  height: 31px;
  background: white;
}

.common-date-picker.invalid {
  border: 1px solid red;
}

.common-date-picker .react-date-picker__wrapper {
  border: none;
  border-radius: 2px;
}

.common-date-picker .react-date-picker__inputGroup input {
  color: #858796 !important;
}

.common-date-picker .react-date-picker__clear-button svg,
.common-date-picker .react-date-picker__calendar-button svg {
  stroke: #858796 !important;
}

.common-date-picker .react-date-picker__clear-button svg:hover,
.common-date-picker .react-date-picker__calendar-button svg:hover {
  stroke: black !important;
}

.common-date-picker .react-calendar {
  position: absolute;
  z-index: 100 !important;
}

.registration-display-image-container {
  width: 100%;
  position: relative;
}

.registration-display-image {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
}

.registration-display-image-cancel-icon {
  position: absolute;
  font-size: 16px;
  right: 0;
  color: #858796;
}

.registration-display-image-cancel-icon:hover {
  color: black;
  cursor: pointer;
}

.ap-subfield-row {
  margin-bottom: 8px;
}

.registration-form-file-control {
  width: 100%;
  position: relative;
  z-index: 0 !important;
}

.registration-form-file-control label {
  color: rgba(0, 0, 0, 0.3);
}

.ap-image-uploader input {
  height: 100% !important;
  background: red;
}

.justify-right {
  justify-content: flex-end !important;
}

.sidebar-block {
  /* background: red; */
}

.pane-container {
  background: var(--backgroundColor);
  width: 100%;
  padding: 0;
  padding-left: 224px;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.pane-container-small-sidebar {
  background: var(--backgroundColor);
  width: 100%;
  padding: 0;
  padding-left: 6.5rem;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.new-sidebar {
  background: var(--sidebarColor);
  width: 224px;
  color: white;
  position: absolute;
  height: 100%;
  padding-top: 8px;
}

.sidebar-line {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 1px;
}

.new-sidebar-closed {
  background: var(--sidebarColor);
  width: 6.5rem;
  color: white;
  position: absolute;
  height: 100%;
  padding-top: 8px;
}

.new-sidebar-closed .admin-list-accordion-title {
  width: 100%;
  word-break: break-all !important;
}

.new-sidebar-closed .admin-list-accordion-collapse.classify-onboarding {
  position: absolute !important;
  left: 100px;
  background: black !important;
  width: max-content;
  transform: translate(100px, 0px) !important;
  border: none;
}

.new-sidebar-closed .dropdown-toggle::after {
  display: none;
}

.new-sidebar-closed .admin-list-accordion-collapse ul li {
  padding: 8px 16px;
}

.new-sidebar-closed
  .admin-list-accordion-collapse.classify-onboarding
  ul
  li:hover {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.new-sidebar-closed {
  width: 6.5rem !important;
}

.new-sidebar-closed .admin-list-item-title {
  font-size: 12px;
  text-align: center;
}

.new-sidebar-closed .admin-list-item-title .row {
  justify-content: center;
}

.new-sidebar-closed .admin-list-accordion .row {
  justify-content: center;
  font-size: 12px;
}

.new-sidebar-closed .admin-list-item-icon {
  font-size: 16px !important;
}

.new-pane-content {
  width: 100%;
}

.tb-navlink {
}

.new-topbar {
  background: transparent;
  padding: 16px;
  padding-left: 240px;
  height: 80px;
  color: white;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.new-topbar-closed {
  background: transparent;
  padding: 16px;
  padding-left: calc(6.5rem + 16px);
  height: 80px;
  color: white;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.new-topbar-row {
  background: red;
  /* height: 100%; */
}

.dark-button {
  background: #332e43;
  border: 1px solid #332e43;
}

.dark-button:hover {
  background: #6c6190;
  border: 1px solid #6c6190;
}

.market-stats {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}

.posRel {
  position: relative !important;
}

.blur1 {
  filter: blur(6px);
}

.clean-nav {
  color: var(--darkHeader) !important;
  padding: 0;
  z-index: 1000;
}

.clean-nav:hover .overlay {
  text-decoration: underline;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-text {
  text-align: center;
}

.padding1 {
  margin-top: 32px;
}

.market-stats-delete-btn {
  display: none;
  cursor: pointer;
  color: black;
}

.market-stats-delete-btn:hover {
  color: blue;
}

.market-statistics-container:hover .market-stats-delete-btn {
  display: block;
}

.market-statistics-container {
  margin: 0 16px;
  width: 100px;
  font-size: 12px;
}

.market-statistics-container .title {
  text-align: center;
  color: var(--sidebarColor);
}

.market-statistics-container .content {
  display: flex;
  text-align: center;
  justify-content: center;
}

.market-statistics-container .first {
  margin-right: 8px;
  color: var(--color-up);
}

.market-statistics-container .second {
  margin-left: 8px;
  color: var(--color-down);
}

.user-dropdown {
  position: absolute;
  right: 16px;
}

.user-dropdown .dropdown-toggle {
  display: flex;
  color: var(--color-grey);
  -ms-align-items: center;
  align-items: center;
  background: transparent !important;
  border: none !important;
}

.user-dropdown .dropdown-menu {
  width: 100%;
  border: none;
  border: 1px solid var(--color-shadow);
  /* background: white !mportant; */
}

.user-dropdown .dropdown-item {
  color: black;
  text-align: right;
}

.user-dropdown .dropdown-item .sidebar-navLink {
  color: black !important;
}

.user-dropdown .dropdown-item:hover {
  background: transparent;
  text-decoration: underline;
}

.user-dropdown .dropdown-toggle::after {
  display: none;
}

.user-dropdown .dropdown-toggle:hover {
  color: var(--color-black);
}

.user-dropdown .dropdown-toggle:focus {
  color: var(--color-black) !important;
}

.connection-icon {
  color: limeGreen;
}

.user-dropdown .username {
  font-size: 16px;
  margin: 0 8px;
  font-weight: 100;
}

.user-dropdown .portrait {
  font-size: 22px;
}

.topbar-register-button {
}

.name-location-container {
  /* background: red; */
}

.name-location-container .location {
  font-size: 12px;
}

.name-location-container .symbol {
  font-size: 12px;
}

.sat-btn-row {
  justify-content: flex-end;
  padding-top: 16px;
}

.sat-btn-container {
  display: flex;
  flex-direction: column;
}

.sat-btn-container button {
  margin-bottom: 4px;
}

.sat-desc {
  /* font-size: 14px; */
}

.trans-button {
  background: transparent;
  border: 1px solid #36b9cc !important;
  color: #2a96a5;
  margin-right: 4px;
  height: 18px;
  font-size: 12px;
  padding: 0 4px;
  width: 100%;
}

.trans-button:hover {
  background: #2a96a5;
  color: white;
}

/* .teal-button {
  background-color: #36b9cc !important;
  border: 1px solid #36b9cc !important;
}

.teal-button:hover {
  background: #2c9faf !important;
  border: 1px solid #2a96a5 !important;
  color: black !important;
} */

.sidebar-navLink {
  color: white !important;
}

.buy-sell-button {
  margin-right: 8px;
}

.buy-sell-button.buy {
  background: transparent;
  color: green;
  border: 1px solid green !important;
}

.buy-sell-button.buy:hover {
  background: green;
  color: white;
}

.buy-sell-button.sell {
  background: transparent;
  color: fireBrick;
  border: 1px solid fireBrick !important;
}

.buy-sell-button.sell:hover {
  background: fireBrick;
  color: white;
}

.utdp-numbers-row {
  display: flex;
  margin: 16px;
}

.utdp-numbers-row .item {
  margin-right: 4px;
  padding: 4px;
  padding-right: 16px;
  font-size: 12px;
}

.utdp-numbers-row .item:not(:last-child) {
  border-right: 1px solid var(--color-border);
}

.utdp-numbers-row .item .top {
  color: var(--color-special);
  text-align: center;
}

.utdp-numbers-row .item .bottom {
  color: var(--color-main);
  text-align: center;
}

.utdp-news-and-research-container {
  background: var(--darkPurple);
  padding: 8px;
}

.utdp-new-article:not(:last-child) {
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--darkText);
}

.utdp-article-title-new {
  font-size: 14px;
  color: var(--darkHeader);
}

.utdp-article-info-new {
  font-size: 12px;
  color: var(--darkText);
}

.utdp-article-link-row {
  justify-content: flex-end;
}

.utdp-article-link-new {
  color: var(--darkHeader);
  font-size: 12px;
}

.utdp-article-link-new:hover {
  color: var(--darkHeader);
}

.utdp-article-link-new svg {
  margin-left: 4px;
}

.see-more-content {
  color: var(--darkHeader) !important;
}

.table-button-container .teal-button {
  margin-right: 4px !important;
}

.discover-banner .search-assets-form-control-new {
  width: 300px;
  margin-right: 8px;
}

.discover-banner-new {
  background: red;
}

.dark-table {
  background: var(--darkPurple);
  color: var(--darkText);
}

.dark-table th {
  border: 1px solid var(--color-shadow) !important;
}

.admin-sidebar-collapse-toggle-button.flip {
  transform: rotate(180deg) !important;
}

.tombstone {
  font-size: 14px;
  position: relative;
  padding-bottom: 100px;
  max-width: 100% !important;
  border: none !important;
  height: max-content !important;
}

.tombstone .card-img {
  width: 120px !important;
}

.tombstone-row {
  justify-content: space-between;
  border-bottom: 1px solid var(--color-shadow);
  margin: 4px 0;
}

.tombstone-left {
  font-weight: bold;
}

.tombstone .pricing-info {
  position: absolute;
  bottom: 8px;
  width: 100%;
  left: 0;
  padding: 8px;
  font-size: 12px;
}

.tombstone .pricing-info .row {
  /* background: orange; */
}

.tombstone .pricing-info .title {
  text-align: right;
  width: 50%;
  padding-right: 8px;
}

.tombstone .pricing-info .value {
  /* text-align: right; */
  width: 50%;
  padding-left: 8px;
}

.tombstone .pricing-info .value#yellow {
  color: goldenRod;
}

.tombstone .pricing-info .value#green {
  color: green;
}

.tombstone .pricing-info .value#red {
  color: red;
}

.swiper-loading-container {
}

.swiper-loading-slide {
  width: 100% !important;
  justify-content: center;
  display: flex;
  height: 300px;
  align-items: center;
}

.swiper-loading-slide .spinner-border {
  color: var(--color-main) !important;
}

.sponsor-accordion {
  border: 1px solid var(--color-shadow);
  padding: 8px;
  border-radius: 3px;
  color: var(--color-main);
  margin-bottom: 16px;
}

.sponsor-collapse {
  margin-top: 16px;
}

.participate-btn-a {
  /* background: red; */
}

.announcements-attachments-file-input {
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 12px;
  border-radius: 3px;
}

.rendered-attachment-box {
  font-size: 12px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px;
  border-radius: 3px;
  margin-bottom: 4px;
}

.rendered-attachment-a:hover {
  color: black;
}

.rendered-attachment-a {
  color: black;
}

.xs-del-btn {
  font-size: 10px;
  padding: 1px 2px;
  text-decoration: none !important;
}

.announcement-section {
  overflow-wrap: break-word;
  word-break: break-word;
  background-color: white;
  padding: 1rem;
}

.announcement-content {
  justify-content: center;
  display: flex;
}

.announcement-block {
  max-width: 680px;
  width: 100%;
  min-width: 0;
  margin: 4rem;
}

@media only screen and (max-width: 62em) {
  .announcement-block {
  }
}

.attachments-section {
  display: flex;
  flex-direction: column;
}

.attachments-section h4 {
  font-weight: 200;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.3);
}

.attachments-section a {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  color: black;
}

.attachments-section a svg {
  margin-right: 0.5rem;
}
