/****************************** MISC STYLING **********************************/
.utdp-container {
  background: transparent;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.utdp-loading-container {
  height: 100%;
  padding-top: 100px;
  max-width: 100%;
  min-width: 100%;
}
/****************************** END OF MISC STYLING ***************************/

/****************************** UTDP HEADER STYLING ***************************/
.utdp-header-container {
  /* width: 100% !important; */
  margin-bottom: 20px;
}

.utdp-header-row {
  margin-right: 5px;
  margin-left: 5px;
}
.utdp-header-column-a {
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
  padding: 15px;
}

.utdp-header-image-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.utdp-token-image-container {
  justify-content: center;
  display: flex;
  align-items: center;
  /* width: 300px; */
}

.utdp-token-image {
  width: 100%;
  max-height: 100px;
  /* height: 90%; */
  object-fit: contain;
}

.utdp-header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.utdp-header-industry {
}

.utdp-header-location {
  color: #858796;
  align-items: center;
}

.location-icon {
  margin-right: 5px;
}

.utdp-header-column-b {
  background: rgba(0, 0, 0, 0.1);
}

.utdp-header-button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  font-size: 14px;
}

.utdp-header-button {
  width: 180px;
  font-size: 14px;
  border-radius: 25px;
  margin-right: 40px;
}
/*********************** END OF UTDP HEADER STYLING ***************************/

/****************************** UTDP DETAILS STYLING **************************/
.utdp-details-header-text {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  text-align: center;
  margin-bottom: 15px;
  color: #858796;
}
/**************************** END OF UTDP DETAILS STYLING *********************/

/************************ UTDP DESCRIPTION STYLING ****************************/
.utdp-description-container {
  width: inherit;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  color: #858796;
  margin-left: 0px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-description-section {
  width: 100%;
}

.utdp-description-text span {
  background: transparent !important;
  color: white !important;
  font-size: 16px !important;
}

.utdp-description-header {
  font-weight: bold;
  font-size: 14px;
}

.utdp-description-text {
  font-size: 16px;
  color: #858796;
}

.utdp-description-container#video {
  justify-content: center;
  display: flex;
  align-items: center;
}
/*********************** END OF UTDP DESCRIPTION STYLING **********************/

/**************************** UTDP TEAM STYLING *******************************/
.utdp-team-container {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  color: #858796;
  width: inherit;
  margin-left: 0px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-team-section {
  width: inherit;
}

.utdp-individual-team-member {
  border-bottom: 1pt solid #1588ee;
  width: inherit;
  font-size: 14px;
  padding-left: 40px;
}

.utdp-team-member-name {
  font-weight: bold;
  color: #858796;
}

.utdp-team-member-name:hover {
  color: white !important;
}
/*************************** END OF UTDP TEAM STYLING *************************/

/**************************** UTDP SOCIAL MEDIA STYLING ***********************/
.utdp-social-media-container {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  color: #858796;
  width: inherit;
  margin-left: 0px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-social-media-section {
  width: inherit;
  height: inherit;
}

.utdp-social-media-icons-row {
}

.utdp-social-media-icons-column {
}

.utdp-social-media-icons-column {
  display: flex;
  justify-content: center;
}

.utdp-social-icon {
  padding-right: 40px;
  font-size: 36px;
  transition: color 0.12s;
  color: #858796;
}

.utdp-social-icon:hover {
  color: #858796;
}
/********************* END OF UTDP SOCIAL MEDIA STYLING ***********************/

/************************* STO DETAILS STYLING ********************************/
.utdp-sto-details-container {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  color: #858796;
  width: inherit;
  margin-left: 0px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-sto-details-section {
  width: 100%;
}

.utdp-sto-details-top-row {
  font-size: 13px;
}

.utdp-sto-details-row {
  border-bottom: 1pt solid rgba(0, 0, 0, 0.13);
  width: 100%;
  margin-bottom: 10px;
  margin-left: 0;
}

.utdp-sto-details-top-column {
}

.utdp-sto-details-column {
  width: 100%;
  padding: 0;
}

.utdp-sto-details-column a {
  color: rgba(255, 255, 255, 0.8);
  justify-content: flex-end;
  display: flex;
  text-decoration: underline;
}

.utdp-sto-details-column a:hover {
  color: #858796;
}

.utdp-title {
  font-weight: bold;
  margin-bottom: 3px;
  color: #858796;
}

.utdp-sto-details-content {
  text-align: right;
  color: rgba(255, 255, 255, 0.8);
}

.utdp-sto-details-document-row {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 10px;
}

/**************************** UTDP NEWS STYLING *******************************/
.utdp-news-header-text {
  text-align: center;
  color: #858796;
}

.utdp-news-section {
  width: inherit;
  margin-bottom: 20px;
  margin-top: 16px;
  margin-left: 0px;
  padding: 20px;
  padding-top: 0;
}

.utdp-individual-news-card {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  margin-bottom: 10px;
  padding: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-news-card-header-row {
  margin-bottom: 10px;
}

.utdp-news-card-info {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}

.utdp-news-card-title {
  font-size: 14px;
  padding: 0px 15px;
  margin-bottom: 15px;
}

.utdp-news-card-title a {
  color: #858796;
}

.utdp-news-card-title a:hover {
}

.utdp-read-more-row {
  /* margin-left: 5px; */
  width: 100%;
  margin-bottom: 10px;
  text-align: right;
}

.utdp-no-news {
  margin-top: 100px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.utdp-nav-bar {
  color: #858796;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
  border: 1px solid transparent;
  border-bottom: 0;
}

.utdp-nav-bar p {
  margin: 0;
}

.utdp-nav-item {
  font-size: 20px;
  justify-content: center;
  display: flex;
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.utdp-nav-item a {
  color: inherit;
}

.utdp-nav-item a:hover {
  text-decoration: none;
}

.utdp-nav-item:hover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* border-bottom: 1px solid transparent; */
  background: white;
}

.utdp-nav-item#current-position {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid transparent;
  background: white;
  color: black;
}

/* .utdp-nav-item#current-position p {
  border-bottom: 3px solid #2E2E31;
} */

/* .utdp-nav-item p:hover {
  cursor: pointer;
  border-bottom: 3px solid #2E2E31;
} */

.utdp-inner-content-div {
  margin: 0 17px 17px 17px;
  padding: 16px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* border-top: 0; */
  display: flex;
}

.utdp-profile-info {
}

.pushed-right {
  /* padding-left: 250px; */
}

.individual-sections-container {
  /* width: 70%; */
}

.utdp-news-row {
  background: white;
  justify-content: center;
  padding: 16px;
}

.utdp-individual-section {
  /* padding: 32px 48px 0 32px; */
  /* width: 100%; */
  margin-bottom: 16px;
  /* background: white; */
  /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
}

.utdp-individual-section h3 {
  color: var(--color-main);
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.utdp-fields-row {
}

.utdp-tile-row {
  overflow-y: hidden;
}

.utdp-tile-paragraph {
  font-size: 14px;
  cursor: pointer;
}

.utdp-tile-paragraph:hover {
  background: rgba(0, 0, 0, 0.1);
}

.utdp-tile-subfield {
  /* background: white; */
  margin-bottom: 8px;
  border-radius: 3px;
  padding: 4px;
  text-align: center;
  font-weight: 100;
}

.utdp-tile-object-container {
}

.utdp-tile-object-image-row {
  justify-content: center;
  margin-bottom: 8px;
}

.utdp-individual-field {
  border-bottom: 1pt solid var(--color-shadow);
  margin-bottom: 16px;
  height: max-content;
  width: 100%;
  padding-bottom: 8px;
}

.utdp-individual-field#hidden {
  display: none;
}

.utdp-tile-image-row {
  justify-content: center;
}

.utdp-tile-modal-image {
  width: 100%;
  object-fit: contain;
}

.utdp-individual-field-label {
  margin: 0;
  /* color: var(--darkHeader); */
  width: 200px !important;
  height: 100%;
  color: var(--color-main);
  /* font-weight: bold; */
}

.utdp-individual-field-text-content {
  margin: 0;
  color: var(--darkText);
  font-weight: 100;
}

.utdp-profile-sections-container {
  color: black;
  /* width: 15%; */
  /* position: fixed; */
  /* left: 17px; */
  /* max-height: 400px; */
  /* top: 450px; */
  /* overflow-y: scroll; */
  /* top: 100px;
  bottom: 100px; */
}

.is-current-profile-section {
}

.sticky {
  position: fixed;
  top: 0;
}

.utdp-profile-sections-header {
  justify-content: center;
  padding-bottom: 8px;
  font-size: 20px;
}

.utdp-individual-section-for-list {
  width: 100%;
  text-transform: uppercase;
  padding: 20px;
}

.utdp-individual-section-for-list:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.utdp-individual-section-for-list:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.utdp-coming-soon {
  color: #858796;
  justify-content: center;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.13);
  padding: 20px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.utdp-news-container {
  max-width: 1000px;
  min-width: 800px;
  padding: 32px;
}

.utdp-individual-article {
  margin-bottom: 16px;
  width: 100%;
  color: #858796;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.utdp-individual-article a {
  /* color: #253165 !important; */
  color: black !important;
}

.utdp-individual-article a:hover {
}

.utdp-article-info {
  color: #858796;
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.utdp-article-title {
  margin: 0;
  font-size: 22px;
  color: black;
  /* color: #253165; */
}

.utdp-article-summary {
  /* color: black; */
  margin-bottom: 8px;
}

.utdp-article-link {
  color: #253165 !important;
  font-size: 14px;
}

.utdp-article-link button {
  padding: 0;
  font-size: 10px;
  padding: 2px 4px;
}

.date-p {
  margin: 0;
}

.utdp-field-image {
  max-width: 40px;
  max-height: 40px;
}

.utdp-field-image-tooltip {
  width: 100%;
  max-height: 400px;
}

.user-content-container {
  min-height: 100vh;
  position: relative;
  color: #858796;
  /* padding: 36px; */
  justify-content: center;
  display: flex;
  padding: 0;
}

.asset-profile-container {
  /* width: 1000px; */
  /* padding: 0; */
  padding: 0;
}

.utdp-header-section {
  background: var(--darkPurple);
  font-size: 13px;
  padding: 16px;
  position: relative;
  margin: 16px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  color: #2e2e31;
}

.utdp-header-desc {
  color: var(--darkText);
}

.button-navlink {
  padding: 0;
  margin-left: 8px;
}

.button-navlink button {
  padding: 0 8px;
}

.utdp-header-name {
  font-size: 48px;
  align-items: center;
  justify-content: space-between;
  color: var(--darkHeader);
}

.utdp-header-info-row {
  margin-top: 16px;
}

.utdp-header-info {
  font-size: 14px;
}

.utdp-header-info .row {
  align-items: center;
}

.utdp-header-info-icon {
  margin-right: 8px;
  height: 100%;
}

.utdp-scrollspy-col {
  justify-content: center;
  margin-right: 16px;
}

.profile-scrollspy {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.individual-section-for-scrollspy {
  width: 100%;
  color: black;
  margin-bottom: 8px;
}

.individual-section-for-scrollspy button {
  width: 100%;
  max-width: 200px;
  height: 100% !important;
}

.individual-section-for-scrollspy:hover {
  /* text-decoration: none;
  background: rgba(0, 0, 0, 0.05) */
}

.individual-section-for-scrollspy:not(:last-child) {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.utdp-see-more-row {
  justify-content: center;
  text-decoration: underline;
  font-size: 16px;
  padding: 32px 0;
}

.utdp-see-more-row a {
  color: black;
}

.utdp-image-row {
  width: 100%;
  margin-bottom: 8px;
}

.utdp-image {
  max-width: 300px;
}

.utdp-image:hover {
  cursor: pointer;
}

.utdp-video {
  border: none;
  height: 240px;
  width: 360px;
}

.utdp-anchor {
}

.utdp-tile-row {
  /* background: red; */
}

.utdp-tile-row:hover {
  background: rgba(0, 0, 0, 0.1);
}

.utdp-individual-tile-field {
  width: 300px;
  border-radius: 3px;
  margin: 0 16px 16px 16px;
  margin-left: 0;
  padding: 16px;
  overflow-y: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.utdp-individual-tile-field label {
  color: var(--color-main);
}

.utdp-individual-tile-field:not(:last-of-type) {
}

.utdp-individual-tile-field:hover {
  /* background: rgba(0, 0, 0, 0.1); */
}

.utdp-individual-tile-field::-webkit-scrollbar {
  width: 4px;
}

.utdp-individual-tile-field::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 25px;
}

.utdp-individual-tile-field .utdp-image-row {
  justify-content: center !important;
  margin: 0 !important;
}

.utdp-individual-tile-field .utdp-image {
  width: 100%;
}

.utdp-tile-image {
  width: 150px;
  border-radius: 100px;
  height: 150px;
  object-fit: cover;
}

.utdp-tile-image:hover {
  cursor: pointer;
}

.utdp-tile-test {
  justify-content: center;
}

.tile-icon {
  /* font-size: 64px !important; */
}

.utdp-individual-tile-field .utdp-social-icon-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-form-link-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-social-icon-link {
  font-size: 40px;
}

.utdp-individual-tile-field .utdp-doc-row {
  width: 100%;
  justify-content: center;
  min-height: 100px;
  align-items: center;
}

.utdp-individual-tile-field .utdp-video {
  height: 100%;
  width: 100%;
}

.utdp-individual-tile-field .utdp-form-link-row {
  /* justify-content: center; */
}

.utdp-offering-wrapper {
  margin-top: 10px;
  margin-left: 10%;
  margin-right: 10%;
  border-style: solid;
  border-color: #253165;
  border-radius: 5px;
}

.utdp-offering-row {
  margin-bottom: 16px;
  /* width: 1000px; */
}

.utdp-offering-header-row {
  background: #b3cbe3;
  padding: 8px;
  height: 45px;
  align-items: center;
  color: #212a5c;
}

.utdp-offering-table-row {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 8px;
  /* background: white; */
}

.utdp-table-title {
  /* width: 200px; */
  display: flex;
  align-items: center;
}

.utdp-offering-table {
  font-size: 14px;
  border: none;
  color: #858796;
  margin: 0;
  /* table-layout: fixed; */
}

.utdp-offering-table tr {
  border: none;
}

.utdp-offering-table th {
  border: none;
  padding: 0;
  border-bottom: 8px solid transparent !important;
  justify-content: flex-end !important;
  width: 200px;
  text-align: center;
  font-size: 18px;
}

.utdp-offering-table td {
  border: none;
  padding: 0;
  border-bottom: 8px solid transparent !important;
  color: #212a5c;
  font-size: 18px;
  /* width: 200px; */
  text-align: center;
}

.utdp-table-profile-name {
  color: #212a5c;
  font-size: 28px;
}

.utdp-user-image-icon {
  font-size: 20px;
  margin-right: 8px;
}

.utdp-user-image {
  width: 20px;
  height: 20px;
  object-fit: cover;
  margin-right: 8px;
}

.utdp-header-offering-row {
  margin-top: 16px;
  justify-content: space-between;
}

.utdp-header-offering-row .row {
  align-items: center;
}

.utdp-header-offering-icon {
  font-size: 64px;
  margin-right: 8px;
}

.utdp-header-offering-label {
  font-size: 20px;
  margin: 0;
}

.utdp-header-data {
  font-size: 32px;
  margin: 0;
}

.utdp-header-info-row {
  margin: 0;
  align-items: center;
  justify-content: space-between;
}

.utdp-header-link {
  color: #1588ee !important;
}

.light-chart-container {
  background: red;
  margin: 16px;
  margin-bottom: 32px;
}

.light-chart-info-label {
  color: #2e2e31;
}

.multifield-subfield-dropdown-container {
  border-bottom: 1pt solid rgba(0, 0, 0, 0.13);
  margin-bottom: 16px;
  height: max-content;
  width: 100%;
  padding-bottom: 8px;
  display: flex;
}

.multifield-subfield-dropdown-container .fieldName {
  width: 200px;
  color: black;
}

.utdp-string-field {
  margin: 0;
}

.utdp-news-research-container {
  background: red;
  height: 100%;
}

.utdp-bottom-container {
  display: flex;
  margin: 16px;
}

.utdp-bottom-container .col {
  /* margin: 0; */
}

.utdp-big-col {
  margin-right: 16px;
}

.utdp-chart-container {
  background: var(--darkPurple);
  height: 300px;
  margin-bottom: 16px;
}

.utdp-profile-data-container {
  background: var(--darkPurple);
  padding: 8px;
}

.rendered-announcement {
  padding: 16px;
  width: 600px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-bottom: 16px;
}

.announcement-info {
  font-size: 12px;
  font-weight: 300;
}

.utdp-tabs {
  margin: 0 16px;
  /* border: none; */
  /* padding: 0 16px; */
}

.utdp-tabs .nav-link.active {
  /* background: transparent; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
