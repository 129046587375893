.search-assets-top-container {
  /* background: #f8f9fc; */
  /* min-height: 100vh; */
}

.search-assets-form-control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #858796 !important;
  max-width: 50%;
  margin-bottom: 10px;
}

.search-assets-form-control::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.search-assets-table-row {
  align-items: center;
  width: max-content;
}

.search-assets-table-name {
  font-size: 12px;
}

.search-assets-table-cell {
  font-weight: normal;
  font-size: 12px;
}

.search-assets-table-cell-div {
}

.search-assets-table-cell.description {
  font-size: 12px;
}

.search-assets-wrapper {
  overflow: auto;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}

.search-assets-wrapper .search-all-assets-tile {
  display: inline-block;
}

.search-assets-query {
  background: white;
}

.search-assets-tile {
  width: 300px;
  height: 400px;
  padding: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  background: var(--darkPurple);
}

.search-assets-background-container {
  margin-bottom: 16px;
}

.search-assets-tile .pricing-info {
  /* background: red; */
  position: absolute;
  bottom: 8px;
  width: 100%;
  left: 0;
  padding: 8px;
  font-size: 12px;
}

.search-assets-tile .pricing-info .row {
  /* background: orange; */
}

.search-assets-tile .pricing-info .title {
  text-align: right;
  width: 50%;
  padding-right: 8px;
}

.search-assets-tile .pricing-info .value {
  /* text-align: right; */
  width: 50%;
  padding-left: 8px;
}

.search-assets-tile .pricing-info .value#yellow {
  color: goldenRod;
}

.search-assets-tile .pricing-info .value#green {
  color: green;
}

.search-assets-tile .pricing-info .value#red {
  color: red;
}

.test-logo {
  max-width: 130px;
}

.search-assets-tile .card-img {
  width: 130px;
  height: 130px;
  object-fit: contain;
  cursor: pointer;
}

.search-assets-tile .header-content {
  margin-bottom: 16px;
  /* width: max-content; */
}

.search-assets-tile .name-location-container {
  /* width: 60%; */
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

.contract-terms {
  text-align: right;
}

.search-assets-tile .name {
  width: 100%;
  font-size: 18px;
  color: var(--darkHeader);
}

.search-assets-tile .location {
  -ms-align-items: center;
  align-items: center;
  display: flex;
  color: var(--darkText);
}

.search-assets-tile .card-text {
  color: var(--darkText);
}

.search-assets-tile .name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.sa-section-header {
  color: #858796 !important;
  /* font-weight: bold; */
  font-weight: 100;
  font-size: 28px;
}

.sa-content-section:not(:first-child) {
  margin-top: 32px;
}

.search-all-content-row {
  margin: 16px 0;
}

.asset-card-img {
  width: 400px;
}

.asset-card {
  width: 200px;
  height: 300px;
  margin-right: 8px;
  color: #858796;
  margin-bottom: 8px;
  border: 1px solid transparent;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}

.asset-card-image {
  width: 60px;
}

.asset-card img {
  height: 125px;
  object-fit: cover;
  cursor: pointer;
}

.asset-card .card-title {
  font-size: 14px;
  padding: 8px 8px 0 8px;
  margin-bottom: 0;
}

.asset-card-desc {
  padding: 8px;
  font-size: 12px;
}

.asset-card a {
  color: #858796;
}

.asset-card a:hover {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}

.asset-card-image {
}

.all-assets-loading-container {
  height: 332px;
}

.all-assets-loading-container {
  width: 100%;
}

.assets-loading {
  height: 72px;
  width: 72px;
  color: #858796;
}

.assets-swiper {
  min-width: 100%;
  padding-bottom: 16px;
}

.swiper-top-container {
  /* width: 100%; */
}

.sa-big-container {
  width: 100%;
}
