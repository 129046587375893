.register-background {
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-image: none !important;
  background-repeat: no-repeat;
  background-color: #0d064d;
  background-size: 100%;
}
.register-lebel {
  color: black;
  margin-left: 50px;
}

.register-form-container .form-control {
  width: 50%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-bottom: 1px solid #008fff;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline-width: 0px !important;
}

.register-form-container .form-control:focus {
  color: white;
  background-color: transparent;
  border-color: transparent;
  outline: 0 !important;
  border-bottom: 1px solid #008fff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.register-form-container .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.register-form-background {
  background: #f8f9fadb;
}

.register-form-container {
  padding: 30px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  backdrop-filter: blur(2px);
}

.centered-row {
  justify-content: center;
  display: flex;
}

.register-row {
  background: red;
}

.register-col {
  background: orange;
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-buttons-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background: red !important;
  /* padding-bottom: 40px; */
}

.recaptcha-col {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.recaptcha {
  /* margin-top: 20px !important; */
  margin-bottom: 20px;
}

.register-control {
  width: 50%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-bottom: 1px solid #495057;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline-width: 0px !important;
}

.register-control:focus {
  color: #495057;
  background-color: transparent;
  border-color: transparent;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
  border-bottom: 3px solid #0b4d8f;
}

.register-group {
  /* background: red; */
  justify-content: center;
}

.register-group:not(:last-child) {
  margin-bottom: 15px;
}

.disabled-register-button {
}
